<template>
    <div class="global-view-card" v-loading="pageLoad">
        <div class="global-detail-head">
            {{ title }}
        </div>
        <div class="global-detail-content" style="padding: 0 10px;">
            <el-form
                    ref="detailForm"
                    :model="detailForm"
                    :rules="detailRules"
                    class="global-detail-content"
                    label-width="auto"
                    label-suffix=":"
            >
                <el-form-item label="发件人ID" prop="senderId">
                    <el-input
                            v-model="detailForm.senderId"
                            :disabled="detailForm.id"
                            placeholder="请输入发件人ID"
                    />
                </el-form-item>
                <el-form-item label="平台固定短信模板编码" prop="fixedTemplateCode">
                    <el-input
                            v-model="detailForm.fixedTemplateCode"
                            :disabled="detailForm.id"
                            placeholder="请输入平台固定短信模板编码"
                    />
                </el-form-item>
                <el-form-item label="短信类型编码" prop="smsTypeCode">
                    <el-input
                            v-model="detailForm.smsTypeCode"
                            :disabled="detailForm.id"
                            placeholder="请输入短信类型编码"
                    />
                </el-form-item>
                <el-form-item label="短信方模板类型编码" prop="templateCode">
                    <el-input
                            v-model="detailForm.templateCode"
                            placeholder="请输入短信方模板类型编码"
                    />
                </el-form-item>
                <el-form-item label="模板内容" prop="templateContent">
                    <el-input
                            v-model="detailForm.templateContent"
                            placeholder="请输入模板内容"
                            type="textarea"
                            :rows="4"
                            show-word-limit
                            maxlength="300"
                    />
                </el-form-item>
                <el-form-item label="模板用途说明" prop="description">
                    <el-input
                            v-model="detailForm.description"
                            placeholder="请输入模板用途说明"
                            type="textarea"
                            :rows="4"
                            show-word-limit
                            maxlength="300"
                    />
                </el-form-item>
                <el-form-item label="每天限制发送短信次数" prop="limitTime" :required="!notLimited">
                    <el-input
                            v-model="detailForm.limitTime"
                            placeholder="请输入每天限制发送短信次数"
                            @input="limitThree('limitTime')"
                            :disabled="limitDisable"
                            style="width: 240px;margin-right: 20px;"
                    >
                        <i style="color: #909399;font-size: 12px;" slot="suffix" class="dw">次</i>
                    </el-input>
                    <el-checkbox v-model="notLimited" @change="setLimited">不限制</el-checkbox>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="detailForm.status">
                        <el-option :value="true" label="启用"></el-option>
                        <el-option :value="false" label="禁用"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item class="global-detail-form-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" :loading="saveLoad" @click="save">保 存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {
        getSmsTemplateDetail, addSmsTemplate, editSmsTemplate
    } from "@/api/business";

    export default {
        data() {
            var validLimitTime = (rule, value, callback) => {
                if (!this.notLimited) {
                    if (!this.detailForm.limitTime) {
                        callback(new Error('请设置每天限制发送短信次数'));
                    } else {
                        callback();
                    }
                } else {
                    callback();
                }
            }
            return {
                pageLoad: false,
                title: "新增短信模板",
                id: null,
                detailForm: {
                    id: this.$route.query.id || null,
                    senderId: null,
                    fixedTemplateCode: "",
                    smsTypeCode: "",
                    templateCode: "",
                    templateContent: "",
                    status: false,
                    description: "",
                    limitTime: null,
                },
                notLimited: false,
                limitDisable: false,
                detailRules: {
                    senderId: [{required: true, message: "请输入发件人ID"}],
                    fixedTemplateCode: [{required: true, message: "请输入平台固定短信模板编码"}],
                    smsTypeCode: [{required: true, message: "请输入短信类型编码"}],
                    templateCode: [{required: true, message: "请输入短信方模板类型编码"}],
                    templateContent: [{required: true, message: "请输入模板内容"}],
                    description: [{required: true, message: "请输入模板用途说明"}],
                    limitTime: [{validator: validLimitTime, trigger: ['blur', 'change']}]
                },
                saveLoad: false,
            };
        },
        created() {
            if (this.$route.query.id) {
                this.title = '编辑短信模板';
                this.getSmsTemplateDetail();
            }
        },
        methods: {
            setLimited(val) {
                this.notLimited = val;
                if (val) {
                    this.detailForm.limitTime = 0;
                    this.limitDisable = true
                } else {
                    this.detailForm.limitTime = null;
                    this.limitDisable = false
                }
            },

            limitThree(name) {//正整数且只能输入3位
                this.detailForm[name] = this.detailForm[name].replace(/^(0+)|[^\d]+/g, '')
            },

            //新增模板
            addSmsTemplate(form) {
                this.saveLoad = true;
                addSmsTemplate(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('添加成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            //编辑模板
            editSmsTemplate(form) {
                this.saveLoad = true;
                editSmsTemplate(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('更新成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            getSmsTemplateDetail() {
                this.pageLoad = true;
                getSmsTemplateDetail({
                    id: this.detailForm.id
                }).then((res) => {
                        Object.assign(this.detailForm, res.data);
                        if (res.data.limitTime == 0) {
                            this.setLimited(true)
                        }
                        this.pageLoad = false;
                    }).catch(() => {
                        this.pageLoad = false;
                    });
            },
            save() {
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        if (this.detailForm.id) {
                            this.editSmsTemplate(this.detailForm)
                        } else {
                            this.addSmsTemplate(this.detailForm)
                        }
                    }
                });
            },
            cancel() {
                this.$router.push("/smsTemplate");
            }
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }
</style>
